export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvează modificările"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esti sigur?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiați ID-ul"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Astăzi la ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ieri la ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu aveți permisiunea de a vorbi pe acest canal."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este conectat"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atașați un mesaj"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți mesajul dvs"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi în jos"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaje noi"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprima"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați în"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezi profil"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editați roluri"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interzicere"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiați utilizator: etichetă"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citat"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editați"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcheaza ca citit"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări server"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Părăsiți serverul"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezactivați canalul"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activați canalul"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cont"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați emojiurile"])},
      "call-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opțiuni de apel"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opțiuni de pornire"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitate de program"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați roboturile"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfață"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbaj"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere cont"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurnalul modificărilor"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caracteristici WIP"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editeaza-ți profilul"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbați parola"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconectați Google Drive"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați Google Drive"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai mult profil"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această informație este văzută de toată lumea."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificați setările de notificare."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunet de notificare"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emite un sunet tare pentru a vă atrage atenția"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificare push"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primiți notificări push chiar și atunci când aplicația este închisă."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încarcă-ți propriile emoji-uri frumoase gratuit! Emoji-urile trebuie să aibă 3 MB sau mai puțin."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipurile de fișiere acceptate sunt: ​​png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați emojiuri"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opțiuni de pornire a aplicației desktop"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a modifica aceste setări, trebuie să descărcați aplicația desktop Nertia."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschideți Nertivia la pornire"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partajați programe sau jocuri pe care le rulați adăugând aplicații mai jos. Aceasta va înlocui starea dvs. personalizată atunci când un program este deschis și va reveni înapoi când programul este închis."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a utiliza funcția de activitate a programului, trebuie să descărcați aplicația desktop Nertivia."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați utilizatori de bot care vă vor permite să utilizați API-ul nostru pentru a vă crea propriii roboți fantezi."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați comenzi pentru bot pentru a informa cu ușurință utilizatorii ce comenzi există pentru botul dvs"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați bot"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați comanda"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editați Bot"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editați comenzi"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați linkul de invitație"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiați adresa URL a invitației"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați tokenul"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișează Token"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiere Token"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetează Tokenul"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți bot"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți pe cale să vă ștergeți contul"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce va fi șters"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nu va fi șters"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajele tale trimise către servere publice"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerințe"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lăsați/Ștergeți toate serverele"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți toți roboții"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterge contul meu"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poate fi necesară reîncărcarea după schimbarea limbii. Contribuiți trimițând un PR în depozitul GitHub."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducători"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbați aspectul lui Nertivia."])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culori"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format de timp"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema implicită"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED Dark"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halloween 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teme predefinite"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSS personalizat"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bară de navigare"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activați funcțiile nefinalizate sau experimentale."])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există funcții WIP disponibile momentan."])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisiuni"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activează TOATE permisiunile"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite mesaje"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimiteți mesaje către canalele serverului"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați rolurile"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizați sau ștergeți roluri"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați canalele"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizați sau ștergeți canale"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick la un utilizator"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick & restricționați un utilizator să se alăture"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați canalele"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați rolurile"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați invitațiile"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizibilitatea serverului"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați notificările"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori interziși"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionare Utilizatorilor"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șterge serverul"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionează-ți rolurile."])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol implicit"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol Bot"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un rol nou"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest rol nu poate fi șters/alocat unui alt membru, deoarece a fost creat de un bot. Eliminați botul pentru a elimina acest rol."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest rol nu poate fi șters sau anulat, deoarece acesta este rolul implicit care se aplică fiecărui membru."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alegeți culoarea rolului"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume rol"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șterge rol"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea rolului..."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascunde rolul"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări generale ale serverului"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați-vă canalele."])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șterge canalul"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se șterge canalul..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numele canalului"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesta este un canal implicit. Puteți schimba canalul implicit în pagina General. Canalele implicite nu pot fi șterse."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un canal nou"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite mesaje"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limită de rată (secunde)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal text"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal HTML (WIP)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulați interzicerea membrilor de aici"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anularea interdicției..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unban"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numai administratorii pot vedea invitațiile tuturor."])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați invitații"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitație personalizată"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvați linkul personalizat"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați invitație"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizări"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caută utilizator"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastați numele serverului pentru a confirma"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificați setările de notificare."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceste setări se vor aplica numai pentru dvs. și nu îi vor afecta pe alții."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activați notificările"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezactivați sunetul"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezactivați notificările"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " scrie..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " și ", _interpolate(_list(1)), " scrie..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " și ", _interpolate(_list(2)), " scrie..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " oameni scriu..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prieteni"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note salvate"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adaugă prieten"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți clic pentru a adăuga un statut personalizat"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totul este gata!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaje directe"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servere"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploră"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bord"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un prieten introducând numele de utilizator și eticheta acestuia."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugând..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adaugă server"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alăturați-vă unui server introducând un cod de invitație."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creează-ți propriul server. Începeți prin a numi serverul dvs."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A te alătura"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deconectat"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectat"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departe"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocupat"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caută să Joace"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Memberi (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Deconectați (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prieteni comuni"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecusoane"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S-a alăturat"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendat"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șterge prieten"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptă cererea"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulează cererea"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adaugă prieten"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite mesaj"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servere comune"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se încarcă..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gata!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticating..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizare"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizare disponibilă"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiază"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șterge"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiat!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a părăsit serverul"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a fost lovit."])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a fost interzis"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s-a alăturat serverului!"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu se poate conecta la server."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modul Rate Limit"])}
  }
}